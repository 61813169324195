import React from 'react';
import './HeroSection.css';

function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  subheading,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
  return (
    <>
      <ul class="box-area">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='home__hero-text-wrapper'>
              <h1 className={lightText ? 'heading' : 'heading dark'}>
                {headline}
              </h1>
              <h1 className={lightText ? 'subheading' : 'subheading dark'}>
                {subheading}
              </h1>
              <p
                className={
                  lightTextDesc
                    ? 'home__hero-subtitle'
                    : 'home__hero-subtitle dark'
                }
              >
                {description}
              </p>
              <div className={lightTextDesc ? 'top-line' : 'top-line dark'}>{topLine}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;