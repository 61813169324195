import React from "react";
import "./ContactInfo.css";

function ContactInfo() {
  var address =
    "75-1, Pusat Dagangan NZX, Jalan PJU1A/41B, Ara Damansara, 47301 Petaling Jaya, Selangor Darul Ehsan";
  var email = "ecsvision.mgmt@gmail.com";
  var mobile1 = "012-334 7378";
  var mobile2 = "012 215 4056";

  return (
    <div className="contactInfo__section">
      <div className="contactInfo__wrapper">
        <div className="contactInfo__container">
          <div className="contactInfo__container-card">
            <div className="contactInfo__container-cardInfo">
              <div className="contactInfo__container-img">
                <img src="images/placeholder.png" />
              </div>
              <h3>{address}</h3>

              <div className="contactInfo__container-img">
                <img src="images/mail.png" />
              </div>
              <a href = "mailto: ecsvision.mgmt@gmail.com?subject = Feedback&body = Message">{email}</a>

              <div className="contactInfo__container-img">
                <img src="images/telephone.png" />
              </div>
              <a href="https://wa.me/60123347378" target="_blank">{mobile1}</a>
              <a href="https://wa.me/60122154056" target="_blank">{mobile2}</a>

            </div>
          </div>

          <div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d995.9801547224898!2d101.58799822922121!3d3.1157024389806525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4e9e61c84039%3A0xf4e53d1d5bf93a77!2s75%2C%20Jalan%20PJU%201A%2F41B%2C%20Pusat%20Dagangan%20NZX%2C%2047301%20Petaling%20Jaya%2C%20Selangor!5e0!3m2!1sen!2smy!4v1657983815065!5m2!1sen!2smy"
              className="contactInfo__container-map"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactInfo;
