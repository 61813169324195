import React from 'react';
import './Fogging.css';

function Fogging() { 
    var foggingDesc = 'We provide fogging among our other services, during which we target the best time to destroy the mosquitoes when they are the most active.'

    return (
        <div className='container'>
            <div className='row fogging-row'> 
                <div className='fogging-text-wrapper'>
                    <h1 className='fogging-heading'>Fogging</h1>
                    <h4 className='fogging-subheading'>{foggingDesc}</h4>
                    <div className='fogging-img'>
                        <img src='images/services/imageFogging1.png' />
                        <img src='images/services/imageFogging2.png' />
                        <img src='images/services/imageFogging3.png' />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Fogging;






