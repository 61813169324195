export const aboutObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Total Quality & Customers Satisfaction',
    headline: 'About Us',
    description:
      'We offer a wide range of cleaning services, ranging from residential condominium and office cleaning, to factory environments in public and private sector markets cleaning services. We are always committed to meeting challenges with firm emphasis on exceeding.',
    buttonLabel: 'Our Services',
    imgStart: '',
    img: '',
    alt: ''
  };
  