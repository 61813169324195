import React from 'react';
import './History.css';
import { Button } from '../Menu/Button';
import { Link } from 'react-router-dom';

function History() {
  var histDesc = "We are an established and reputable cleaning company which has been operating in the cleaning industry since 1996. " + 
  "We have provided cleaning services in the past 26 years throughout Malaysia and our core activities are in Commercial and Industrial " +
  "Cleaning Services. We specialised in commercial and industrial cleaning service tasks that can be specially planned according " +
  "to our clients’ requirements.";

  return (
    <div className='history__section'>
      <h1 className='history__heading'>Our History</h1>
      <div className='history__wrapper'>
        <div className='history-items'>
          <div className='history-container '>
            <img src='images/clean-bg.png' alt='History' className='history-img'/>
          </div>
        </div>

        <div className='history-items'>
            <h3 className='history__description'>{histDesc}</h3>
               
            <Link to='/service'>
              <Button buttonSize='btn--wide' buttonColor='blue'>
                  LEARN MORE
              </Button>
            </Link>
           
        </div>
      </div>
    </div>
  );
}
export default History;
