import React from 'react';
import './Vision.css';
import { Link } from 'react-router-dom';
import { Button } from '../Menu/Button';

function Vision() { 
    var vision = "Delivering quality services by doing right at the 1st time to our customer is our main vision. To sustain and growth together as our partnership while we will serve all our customer with high integrity and full responsibility.";
    var mission = "With the professionalism and determination of our management team, our mission is to become the prime vendor in providing its excellent service offerings. With these commitments, ECS has been able to achieve and maintain exceptional results in all its business activities.";
    
    return (
        <div className='vision__section'>
            <div className='vision__wrapper'>
                <h1 className='vision__heading'>Vision and Mission</h1>
                <div className='vision__container'>
                    <div className='vision__container-card'>
                        <div className='vision__container-cardInfo'>
                            <div className='vision__container-img'>                
                                <img src='images/vision.png' />
                            </div>
                            <h2>Our Vision</h2>
                            <p>{vision}</p>
                        </div>
                    </div>
                    <div className='vision__container-card'>
                        <div className='vision__container-cardInfo'>
                            <div className='vision__container-img'>                
                                <img src='images/mission.png' />
                            </div>                                
                            <h2>Mission</h2>
                            <p>{mission}</p>
                        </div>
                    </div>
                </div>

                <h1 className='vision__heading'>What We Do?</h1>
                <Link to="/service" className='vision__container-features'>
                    <Button buttonSize='btn--wide' buttonColor='blue'>
                        Our Services
                    </Button>
                </Link>
            </div>
        </div>
    );
}
export default Vision;
