import React from 'react'
import HeroSection from '../../Shared/HeroSection';
import Listing from '../../Shared/Listing';
import Fogging from '../../Shared/Fogging';
import { serviceObjOne } from './Data';
import Cleaning from '../../Shared/Cleaning';
import Disinfection from '../../Shared/Disinfection';

function Service() {
  return (
    <>
    <HeroSection {...serviceObjOne}/>
    <Listing />
    <Cleaning />
    <Fogging/>
    <Disinfection />
    </>
  )
}

export default Service