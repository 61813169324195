import React from 'react';
import { Button } from '../Menu/Button';
import { Link } from 'react-router-dom';
import './Stacked.css';

function Stacked() {
    var buttonLabel = "Read More";
    const serviceLabel = {
        firstService: 'Industrial and Commercial Cleaning of Buildings',
        secondService: 'Sourcing and Supply of Washroom Hygiene Products',
        thirdService: 'Sales and Service of Cleaning Solutions and Materials'
    }
    return (
        <div className="stacked__wrapper">
            <h1 className='stacked__heading'>Our Services</h1>
            <h3 className='stacked__subheading'>We has always been dedicated to provide long term solutions and enhancements to grow your businesses resulting in a better quality of life.</h3>
            <div className='stacked__container'>
                <Link to='/service'>
                    <div className="stacked">
                        <img src='images/bucket.png' alt="building" />
                        <p>{serviceLabel.firstService}</p>
                    </div>
                </Link>

                <Link to='/service'>
                    <div className="stacked">
                        <img src='images/wash.png' alt="washroom"/>
                        <p>{serviceLabel.secondService}</p> 
                    </div>
                </Link>
              
                <Link to='/service'>
                    <div className="stacked">
                        <img src='images/household.png' alt="Cleaning"/>
                        <p>{serviceLabel.thirdService}</p>
                    </div>
                </Link>
               
            </div>
        </div>
    );
}

export default Stacked;