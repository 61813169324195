import React, { useEffect, useState } from 'react';
import "./ContactForm.css";
import { Button } from '../Menu/Button';
import { validateEmail, validateFullName, validateMessage, validatePhone, validateCompanyName} from '../Shared/ContactUs/Validation';
import InlineError from '../Shared/ContactUs/InlineError';
import Loading from '../Shared/ContactUs/Loading';
import Toast from '../Shared/ContactUs/Toast';
import { toast } from 'react-toastify';
import { SendEmail } from '../Shared/ContactUs/SendEmail';

function ContactForm() {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [fullNameError, setFullNameError] = useState();
    const [emailError, setEmailError] = useState();
    const [phoneError, setPhoneError] = useState();
    const [messageError, setMessageError] = useState();
    const [companyNameError, setCompanyNameError] = useState();
    const [loading, setLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [send, setSend] = useState();

    useEffect(() => {
        // *********** VALIDATION **********
        validateFullName({ fullName, setFullNameError });
        validateEmail({ email, setEmailError });
        validatePhone({ phone, setPhoneError });
        validateMessage({ message, setMessageError });
        validateCompanyName({ companyName, setCompanyNameError });
    
        // ***********
        if (send) {
          toast.success(send.msg);
          setFullName("")
          setEmail("")
          setMessage("")
          setSend()
          setPhone("")
          setCompanyName("")
        }
    }, [fullName, email, phone, companyName, message, send]);

    const submitHandler = (e) => {
        // console.log("TEstinggg");
        e.preventDefault();
        setButtonLoading(true);
        if (!fullNameError & !emailError & !phoneError & !messageError & !companyNameError) {
            console.log("send");
          SendEmail({ fullName, email, phone, message, companyName, setSend }).then(
            () => {
              setButtonLoading(false);
            }
          );
        }
    };

    return (     
        <section class="contact_us">
            <div class="container">
                <div class="contact_inner">
                    <div class="contact_form_inner">
                        <form onSubmit={submitHandler} class="contact_field">
                            <h3>Contact Us Now</h3>
                            <label>Name</label>
                            <input type="text" value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required
                            class="form-control form-group"/>
                            <p>{fullNameError && <InlineError error={fullNameError} />}</p>

                            <label>Company Name</label>
                            <input type="text" value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            required
                            class="form-control form-group" />
                            <p>{companyNameError && <InlineError error={companyNameError} />}</p>

                            <label>Email</label>
                            <input type="email" value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            class="form-control form-group" />
                            <p>{emailError && <InlineError error={emailError} />}</p>

                            <label>Phone</label>
                            <input type="tel" value={phone}
                            onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                            required
                            class="form-control form-group" />                           
                            <p>{phoneError && <InlineError error={phoneError} />}</p>

                            <label>Message</label>
                            <textarea value={message}  onChange={(e) => setMessage(e.target.value)}
                            class="form-control form-group"></textarea>
                            <p>{messageError && <InlineError error={messageError} />}</p>

                            <div className='contact-space'></div>
                            <Button
                                type="submit"
                                disabled={buttonLoading && true}
                                buttonSize='btn--wide' buttonColor='blue'
                            >
                                {buttonLoading ? 'Loading..' : 'SUBMIT'}
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default ContactForm;