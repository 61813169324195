import React from 'react'
import Vision from '../../Shared/Vision';
import HeroSection from '../../Shared/HeroSection';
import { aboutObjOne } from './Data';

function About() {
  return (
    <>
    <HeroSection {...aboutObjOne}/>
    <Vision />    
    </>
  )
}

export default About