export const contactObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: '',
    headline: 'Contact Us',
    description:
      'Contact us directly on Whatsapp or call today and we will be happy to assist you with your query! You may also fill up the contact form and we will get back to you as soon as possible.',
    buttonLabel: 'Get a free quote',
    imgStart: '',
    img: '',
    alt: ''
  };
  