import React from 'react';
import './Listing.css';
import { Button } from '../Menu/Button';

function Listing() { 
    var firstService = "Industrial and Commercial Cleaning of Buildings"
    var secondService = "Sourcing and Supply of Washroom Hygiene Products"
    var thirdService = "Sales and Service of Cleaning Solutions and Materials"

    const servicesOne = [
        'Residential Condominium', 'Corporate Offices', 'Factory Buildings', 'Retail and Complexes', 
        'Facilities buildings', 'Sports and Recreations Complexes'
    ];

    const serviceOneItems = servicesOne.map((s1) => <li>{s1}</li>);

    const servicesTwo = [
        'Tissues and Towels', 'Hand Sanitizer', 'Disinfectants & Fresheners', 'Product Dispensers', 
        'Any other related hygiene products'
    ];

    const serviceTwoItems = servicesTwo.map((s2) => <li>{s2}</li>);

    const servicesThree = [
        'Commercial Cleaning Chemical Solutions', 'Green Cleaning Solutions', 
        'Natural Cleaning Materials',
    ];

    const serviceThreeItems = servicesThree.map((s3) => <li>{s3}</li>);
        
    return (
        <div className='listing__section'>
            <div className='listing__wrapper'>
                <h1 className='listing__heading'>Main Cleaning Services</h1>
                <div className='listing__container'>
                    <div className='listing__container-card'>
                        <div className='listing__container-cardInfo'>
                            <h3>{firstService}</h3>
                            <ul>{serviceOneItems}</ul>
                        </div>
                    </div>
                   
                   <div className='listing__container-card'>
                        <div className='listing__container-cardInfo'>                               
                            <h3>{secondService}</h3>
                            <ul>{serviceTwoItems}</ul>
                        </div>
                   </div>

                   <div className='listing__container-card'>
                        <div className='listing__container-cardInfo'>
                            <h3>{thirdService}</h3>
                            <ul>{serviceThreeItems}</ul>
                        </div>
                   </div>
                </div>

                <div className='gallery'>
                    <img src='images/services/imageListing1.png' className='gallery-img'/>
                    <img src='images/services/imageListing2.png' className='gallery-img'/>
                    <img src='images/services/imageListing3.png' className='gallery-img'/>
                    <img src='images/services/imageListing4.png' className='gallery-img'/>
                    <img src='images/services/imageListing5.png' className='gallery-img'/>
                </div>

            </div>
        </div>
    );
}
export default Listing;
