import React from 'react';
import './Disinfection.css';

function Disinfection() { 
    var disinfectionDesc = 'We provide disinfection services for commercial premises and locations such as office, schools, factory, warehouse or wherever disinfection needed'

    return (
        <div className='container'>
            <div className='row disinfection-row'> 
                <div className='disinfection-text-wrapper'>
                    <h1 className='disinfection-heading'>Disinfection</h1>
                    <h4 className='disinfection-subheading'>{disinfectionDesc}</h4>
                    <div className='disinfection-img'>
                        <img src='images/services/imageDisinfection1.png' className='image-size'/>
                        <img src='images/services/imageDisinfection2.png' className='image-size'/>
                        <img src='images/services/imageDisinfection7.png' className='image-size'/>
                    </div>
                    <div className='disinfection-img'>
                        <img src='images/services/imageDisinfection5.png' className='image-size'/>
                        <img src='images/services/imageDisinfection6.png' className='image-size'/>
                        <img src='images/services/imageDisinfection4.png' className='image-size'/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Disinfection;






