import React from 'react'
import HeroSection from '../../Shared/HeroSection';
import ContactInfo from '../../Shared/ContactInfo';
import ContactForm from '../../Shared/ContactForm';
import { contactObjOne } from './Data';

function Contact() {
  return (
    <>
    <HeroSection {...contactObjOne}/>
    <ContactInfo />
    <ContactForm />
    </>
  )
}

export default Contact