import React from 'react';
import './Choose.css';
import { IconContext } from 'react-icons/lib';

function Choose() {
  return (
    <IconContext.Provider value={{ color: '#fff', size: 64 }}>
      <div className='choose__section'>
        <div className='choose__wrapper'>
          <h1 className='choose__heading'>Why Choose Us?</h1>
          <div className='choose__container'>
            <div className='choose__container-card'>
              <div className='choose__container-cardInfo'>
                {/* <div className='icon'>
                  <FaFire />
                </div> */}
                <div className='choose__container-img'>                
                  <img src='images/choose-pic1.png' />
                </div>
                <h3>Professional Cleaners</h3>
                <p>Highly trained and experienced cleaners to provide high-quality services 
                  that are tailored according to your needs</p>
              </div>
            </div>
            <div className='choose__container-card'>
              <div className='choose__container-cardInfo'>
                {/* <div className='icon'>
                  <BsXDiamondFill />
                </div> */}
                <div className='choose__container-img'>                
                  <img src='images/choose-pic2.png' />
                </div>
                <h3>Proper Standard Of Procedure</h3>
                <p>Developed a proper standard operating procedure (SOP) for all of our services to make sure that 
                  you get the best cleaning service</p>
              </div>
            </div>
            <div className='choose__container-card'>
              <div className='choose__container-cardInfo'>
                {/* <div className='icon'>
                  <GiCrystalize />
                </div> */}
                <div className='choose__container-img'>                
                  <img src='images/choose-pic3.png' />
                </div>
                <h3>Advanced Cleaning Equipment</h3>
                <p>Use the best equipment to provide you quality services that can solve all your cleaning problems</p>
              </div>
            </div>
            <div className='choose__container-card'>
              <div className='choose__container-cardInfo'>
                {/* <div className='icon'>
                  <FaBuilding />
                </div> */}
                <div className='choose__container-img'>                
                  <img src='images/choose-pic4.png'/>
                </div>
                <h3>Great Customer Support</h3>
                <p>Customer support team is available to answer your questions and provide you with all the information you need</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  );
}
export default Choose;
