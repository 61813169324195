import React from 'react';
import './Footer.css';

function Footer() {
  var address = "75-1, Pusat Dagangan NZX, Jalan PJU1A/41B, Ara Damansara, 47301 Petaling Jaya, Selangor Darul Ehsan"
  var email = "ecsvision.mgmt@gmail.com"

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
           Expclean Services Sdn. Bhd
        </p>
        {/* <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> */}
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h3>Address</h3>
            <p>{address}</p>
          </div>

          <div class='footer-link-items'>
            <h3>Contact</h3>
            <a href = "mailto: ecsvision.mgmt@gmail.com?subject = Feedback&body = Message">{email}</a>
            <a href="https://wa.me/60123347378" target="_blank">+012-334 7378</a>
            <a href="https://expcleanservices.com/" target="_blank">www.expcleanservices.com</a>
          </div>
        </div>
      </div>
      {/* <section class='social-media'>
        <div class='social-media-wrap'>
         <div class='footer-logo'>
            <Link to='/' className='social-logo'></Link>
          </div>
          <small class='website-rights'></small> 
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section> */}
    </div>
  )
}

export default Footer