import React from 'react';
import './Cleaning.css';

function Cleaning() { 
    var cleaningDesc = 'We cater to all types of cleaning operations for corporate offices, factories, common lobbies and facilities.'

    return (
        <div className='container'>
            <div className='row cleaning-row'> 
                <div className='cleaning-text-wrapper'>
                    <h1 className='cleaning-heading'>Industrial Cleaning</h1>
                    <h4 className='cleaning-subheading'>{cleaningDesc}</h4>
                    <div className='cleaning-img'>
                        <img src='images/services/imageIndustrial7.png' loading="lazy" className='gallery-img'/>
                        <img src='images/services/imageIndustrial8.png' loading="lazy" className='gallery-img'/>
                        <img src='images/services/imageIndustrial9.png' loading="lazy" className='gallery-img'/>
                        <img src='images/services/imageIndustrial10.png' loading="lazy" className='gallery-img'/>
                        <img src='images/services/imageIndustrial13.png' loading="lazy" className='gallery-img'/>
                        <img src='images/services/imageIndustrial11.png' loading="lazy" className='gallery__full-img'/>
                        <img src='images/services/imageIndustrial12.png' loading="lazy" className='gallery__full-img'/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Cleaning;






