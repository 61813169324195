const validateEmail = ({ email, setEmailError }) => {
    const emailRegular =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && !email.match(emailRegular)
      ? setEmailError('Please insert valid email')
      : setEmailError('');
  };
  
  const validatePhone = ({ phone, setPhoneError }) => {
    var phoneRegular = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return phone && phone.length < 8 && !phone.match(phoneRegular)
      ? setPhoneError('Please insert valid phone number')
      : setPhoneError('');
  };
  
  const validateFullName = ({ fullName, setFullNameError }) => {
    return fullName && fullName.length < 5
      ? setFullNameError('Please insert your full name')
      : fullName && fullName.length > 100
      ? setFullNameError('Try to make short and meaningful')
      : setFullNameError('');
  };

  const validateCompanyName = ({ companyName, setCompanyNameError }) => {
    return companyName && companyName.length < 5
      ? setCompanyNameError('Please insert your company full name')
      : companyName && companyName.length > 100
      ? setCompanyNameError('Try to make short and meaningful')
      : setCompanyNameError('');
  };
  
  const validateMessage = ({ message, setMessageError }) => {
    return message && message.length < 5
      ? setMessageError('Please insert more message')
      : message && message.length > 255
      ? setMessageError('Try to make short and meaningful')
      : setMessageError('');
  };
  
  export { validateEmail, validateFullName, validateMessage, validatePhone, validateCompanyName };