export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Leading service provider of professional cleaning services',
    headline: 'Specialised Cleaning Company In Malaysia    ',
    description:
      'We offer a wide range of cleaning services, ranging from residential condominium and corporate office cleaning, to factory environments in public and private sector markets.',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: ''
  };

  export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Customer Satisfaction Is Always Our No. 1 Priority',
    headline: '',
    subheading: 'Our History',
    description:
      '',
    buttonLabel: '',
    imgStart: 'start',
    img: 'images/clean-bg.png',
    alt: ''
  };
  
  export const homeObjThree = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Highly trained and experienced in all forms of cleaning',
    headline: '',
    description:
      'Our professional cleaners are thoroughly trained in respect to each cleaning task and have each successfully completed all of the necessary safety training involved.',
    buttonLabel: '',
    imgStart: '',
    img: '',
    alt: ''
  };
  