export const serviceObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'We believe quality is important in everything we do',
    headline: 'Our Services',
    description:
      'We specialize in commercial and industrial cleaning service tasks that can be specially done according to your ' +
      'requirements. At ECS, we employ modern methods, use proven equipment and materials together with ' + 
      'experienced cleaners to deliver the utmost in cleaning services.',
    buttonLabel: 'Our Services',
    imgStart: '',
    img: '',
    alt: ''
  };
  