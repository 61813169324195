import React from 'react';
import Navbar from './components/Menu/Navbar';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Footer from './components/Footers/Footer';
import Home from './components/Pages/HomePage/Home';
import About from './components/Pages/AboutPage/About';
import Contact from './components/Pages/ContactPage/Contact';
import Service from './components/Pages/ServicePage/Service';
import ScrollToTop from "./components/Menu/ScrollToTop";
import Whatsapp from './components/Shared/Whatsapp';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Switch>
          <Route path='/' exact component={Home}/>
          {/* TODO */}
          <Route path='/about' exact component={About}/>
          <Route path='/service' exact component={Service}/>
          <Route path='/contact' exact component={Contact}/>
        </Switch>
        <Whatsapp />
        <Footer />
      </Router>
    </>
  );
}

export default App;
