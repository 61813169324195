import React from 'react'
import HeroSection from '../../Shared/HeroSection';
import History from '../../Shared/History';
import Choose from '../../Shared/Choose';
import Stacked from '../../Shared/Stacked';
import { homeObjOne } from './Data';

function Home() {
  return (
    <>
    <HeroSection {...homeObjOne} />
    <History />    
    <Choose />
    <Stacked />
    </>
  )
}

export default Home