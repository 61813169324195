import axios from "axios";

const URL = process.env.REACT_APP_SERVER_URL;
// ******* Send message
export const SendEmail = async ({ fullName, email, phone, message, companyName, setSend,}) => {
    try {
      // console.log("Hiiii", URL);
      const datas = { fullName, email, phone, message, companyName };
      let res = await axios.post(`${URL}/send`, datas);
      
      if (res) {
        setSend(res.data);
      }
    } catch (error) {
      alert(error.response.data.msg);
    }
};